@use '../includes' as *;

.footer__svp {
  margin-block-start: auto;
  padding: $g32 var(--spacing-inline);
  background-color: $pbs-blue;
  color: $cloud;
  font-size: $f14;

  @include breakpoint($sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: $g16;
  }

  @include breakpoint($lg) {
    font-size: $f16;
  }
}

.footer_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $g24;
  margin-block-end: $g32;

  @include breakpoint($sm) {
    flex-direction: row;
    margin: 0;
  }

  @include breakpoint($lg) {
    gap: $g32;
  }
}

.footer_link__pbs {
  svg {
    width: 60px;
    height: 25px;
    // SVGs will have a weird space when the child of an inline element,
    // such as an <a>. This is a fix for that.
    // https://stackoverflow.com/a/22337325/1173898
    vertical-align: top;
  }
}

.footer_copyright {
  text-align: center;

  @include breakpoint($sm) {
    // 490 is a magic number that is the width of the footer links
    max-width: calc(100% - 490px);
    text-align: end;
    text-wrap: balance;
  }
}
