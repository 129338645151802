@use '../includes' as *;

@mixin root-custom-properites-light {
  --body-background-color: #{$white};
  --content-well-background-color: #{$cloud};
  --text-color: #{$midnight};
  --text-color--subdued: #{$slate};
  --link-color: #{$pbs-blue};
  --link-active-color: #{$medium-blue};
  --header-color: #{$medium-blue};
  --header-color-no-link: #{$navy-blue};
  --icon-color: #{$pbs-blue};
  --icon-active-color: #{$medium-blue};
  --box-shadow: #{$box-shadow};
  --popover-background-color: #{$white};
  --light-dark-background-color: #{$fog};
  --lighter-darker-background-color: #{$cloud};
  --lightest-darkest-background-color: #{$white};
  --focus-color: #{$pbs-blue};
}

@mixin root-custom-properites-dark {
  --body-background-color: #{$midnight};
  --content-well-background-color: #{$midnight};
  --text-color: #{$fog};
  --text-color--subdued: #{$pebble};
  --link-color: #{$fog};
  --link-active-color: #{$pebble};
  --header-color: #{$fog};
  --header-color-no-link: #{$fog};
  --icon-color: #{$fog};
  --icon-active-color: #{$yellow};
  --box-shadow: #{$box-shadow-dark-mode};
  --popover-background-color: #{$charcoal};
  --light-dark-background-color: #{$charcoal};
  --lighter-darker-background-color: #{$shadow};
  --lightest-darkest-background-color: #{$midnight};
  --focus-color: #{$teal};
}

:root {
  @include root-custom-properites-dark;
  // for use in margin/padding top & bottom
  --spacing-block: #{$g40};
  // for use in margin margin/padding left & right
  --spacing-inline: #{$g16};
  // for use in smaller gaps, like between show posters in row
  --gutter: #{$g8};
  // variant of gutter for heroes
  --hero-gutter: #{$g12};
  // for use in column gaps
  --responsive-col-gap: #{$g60};
  // for use in row gaps
  --responsive-row-gap: #{$g40};
  // for the header / utility nav
  --navigation-height: #{$navigation-height};
  --navigation-with-border-height: calc(
    var(--navigation-height) + #{$nav-border-width}
  );
  --navigation-image-height: calc(var(--navigation-height) * 0.57);
  --navigation-spacing-inline: #{$g16};
  // for hover border thickness
  --border-thickness: 2px;
  --activate-footer: #{$g72};

  @include breakpoint($sm) {
    --spacing-block: #{$g48};
    --spacing-inline: #{$g32};
    --gutter: #{$g12};
    --hero-gutter: #{$g16};
    --responsive-col-gap: #{$g60};
    --responsive-row-gap: #{$g48};
    --navigation-height: #{$navigation-height-sm};
    --border-thickness: 3px;
    --show-poster-video-row-gap: #{$g8};
  }

  @include breakpoint($md) {
    --show-poster-video-row-gap: #{$g12};
  }

  @include breakpoint($mdlg) {
    --spacing-block: #{$g80};
    --spacing-inline: #{$g64};
    --gutter: #{$g16};
    --hero-gutter: #{$g20};
    --responsive-col-gap: #{$g160};
    --responsive-row-gap: #{$g60};
    --navigation-height: #{$navigation-height-md};
    --navigation-spacing-inline: #{$g12};
    --activate-footer: #{$g76};
  }

  @include breakpoint($xl) {
    --hero-gutter: #{$g24};
    --responsive-col-gap: #{$g160};
    --responsive-row-gap: #{$g80};
    --show-poster-video-row-gap: #{$g16};
    --activate-footer: #{$g80};
  }

  --duration: #{$duration};
  --duration-half: calc(#{$duration} / 2);
  --duration-third: calc(#{$duration} / 3);
  --duration-long: calc(#{$duration} * 1.667);
}

// There is no way to have CSS output when there is a class OR
// a media query - so we have to repeat ourselves here
// @TODO re-add light mode
// @media screen and (prefers-color-scheme: light) {
//   :root:not(.appearance__dark) {
//     @include root-custom-properites-light;
//   }
// }

// :root.appearance__light {
//   @include root-custom-properites-light;
// }

@media (prefers-reduced-motion: reduce) {
  :root {
    --duration: 0s;
    --duration-half: 0s;
    --duration-third: 0s;
    --duration-long: 0s;
  }
}
