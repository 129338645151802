@use '../includes' as *;

@font-face {
  font-family: 'PBS Sans';
  font-weight: $normal;
  src:
    url('https://www-tc.pbs.org/pbsfonts/v1.2/PBSSans.woff2') format('woff2'),
    url('https://www-tc.pbs.org/pbsfonts/v1.2/PBSSans.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PBS Sans';
  font-weight: $medium;
  src:
    url('https://www-tc.pbs.org/pbsfonts/v1.2/PBSSans-Medium.woff2')
      format('woff2'),
    url('https://www-tc.pbs.org/pbsfonts/v1.2/PBSSans-Medium.woff')
      format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'PBS Sans';
  font-weight: $bold;
  src:
    url('https://www-tc.pbs.org/pbsfonts/v1.2/PBSSans-Bold.woff2')
      format('woff2'),
    url('https://www-tc.pbs.org/pbsfonts/v1.2/PBSSans-Bold.woff') format('woff');
  font-display: swap;
}
