@use '../../components/includes' as *;

@mixin cta-styles($color, $hover-color) {
  color: $color;

  svg,
  path {
    height: 0.8em;
    margin-inline: $g8;
    fill: $color;
  }

  &:hover {
    color: $hover-color;

    svg,
    path {
      fill: $hover-color;
    }
  }
}

.configurable_badge__wrapper {
  display: flow-root;
  position: sticky;
  z-index: 21;
  max-height: 0;
  overflow: clip;
  animation: banner-slide-down 1.3s ease-in forwards;
  animation-delay: 0.2s;

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0;
    animation-delay: 0;
  }

  // if the badge has a height set, then we need the container query
  &[style*='height'] {
    container-type: size;
  }
}

.configurable_badge {
  display: flex;
  position: sticky;
  // brings it above hovered show posters
  z-index: 23;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $g4;
  padding-block: $g16;
  padding-inline: var(--spacing-inline);
  animation: banner-fade-in 0.5s ease-in forwards;
  animation-delay: 0.9s;
  opacity: 0;
  animation-fill-mode: forwards;

  @include body-copy-3;

  @include breakpoint($configurable-badge-bp) {
    position: fixed;
    inset: auto var(--spacing-inline) 0 var(--spacing-inline);
    flex-direction: row;
    gap: 4%;
    padding-block: $g4;
    padding-inline: var(--spacing-inline) calc(var(--spacing-inline) + 20px);
    border-radius: 10px 10px 0 0;

    @include body-copy-2;
  }

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0;
    animation-delay: 0;
  }
}

.configurable_badge__headline {
  text-align: center;
}

a.configurable_badge__cta.configurable_badge__cta {
  @include body-copy-3;

  display: flex;
  align-items: center;
  font-weight: $bold;
  white-space: nowrap;

  @include breakpoint($configurable-badge-bp) {
    @include body-copy-2;

    font-weight: $bold;
  }
}

.teal {
  background-color: $teal;
}

.yellow {
  background-color: $yellow;
}

.red {
  background-color: $red;
}

.light_blue {
  background-color: $light-blue;
}

// The color of the text and close button are tied to
// the background color to ensure contrast ratios are adequate
.red,
.light_blue {
  color: $white;

  .configurable_badge__close_button {
    svg,
    path {
      fill: $white;
    }
  }

  a.configurable_badge__cta.configurable_badge__cta {
    @include cta-styles($white, $moon);
  }
}

.teal,
.yellow {
  color: $medium-blue;

  a.configurable_badge__cta.configurable_badge__cta {
    @include cta-styles($pbs-blue, $medium-blue);
  }
}

.configurable_badge__close_button {
  all: unset;
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: var(--spacing-inline);
  width: 20px;
  cursor: pointer;

  @include breakpoint($configurable-badge-bp) {
    inset-inline-end: 20px;
  }
}

.quarter {
  @include breakpoint($max: $sm) {
    height: 25vh;
  }
}

.half {
  @include breakpoint($max: $sm) {
    height: 50vh;
  }
}

.configurable_badge__show_logo {
  img {
    width: auto;
    max-width: 200px;
    max-height: 35px;
  }

  @include breakpoint($configurable-badge-bp) {
    img {
      width: auto;
      max-height: 30px;
    }
  }
}

.passport_lockup.passport_lockup {
  margin-block: 0;
  margin-inline-start: $g4;

  @include breakpoint($max: $configurable-badge-bp) {
    font-size: $f12;
  }
}

.next_icon {
  transform: translate(0, 1px);
}

@keyframes banner-slide-down {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 2000px;
  }
}

@keyframes banner-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
